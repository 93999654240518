import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import AboutUs from "../components/aboutUs";

const AboutUsPage = () => (
    <Layout>
        <SEO title="Mini site - Nos solutions" description={'Personnaliser votre site en ligne'} lang={'fr'}/>
        <main id={'content-page'}>
            <AboutUs/>
        </main>
    </Layout>
)

export default AboutUsPage