import React, {createRef} from 'react';
import { Swiper } from "swiper/react/swiper";
import { SwiperSlide } from "swiper/react/swiper-slide";
import { Navigation } from "swiper";
import pathPictureTeam from '../images/team.jpg';
import '../styles/about-us.scss';
import "swiper/swiper.scss";

import path1998 from '../images/about-us/aussiito-blue.jpg';
import path2010 from '../images/about-us/network.jpg';
import path2019 from '../images/about-us/home.jpg';
import path2021 from '../images/about-us/multi-platform.jpg';
import path2022 from '../images/about-us/mini-site.jpg';

import pathLight from '../images/about-us/light.svg';
import pathRobot from '../images/about-us/robot.svg';
import pathPipette from '../images/about-us/pipette.svg';
import pathMessages from '../images/about-us/messages.svg';

const history = [
    {
        year: 1998,
        description: 'Création de l’entreprise',
        picture: path1998,
    },
    {
        year: 2010,
        description: 'Refonte des outils d\'E-Motors',
        picture: path2010,
    },
    {
        year: 2019,
        description: 'Aménagement dans des nouveaux locaux',
        picture: path2019,
    },
    {
        year: 2021,
        description: 'Finalisation des préprods et lancement de la phase de test pour Tetraweb',
        picture: path2021,
    },
];

const ourValues = [
    {
        picture: pathLight,
        description: 'L\'<b>innovation</b> est en cœur de nos préoccupations et de nos activités.',
    },
    {
        picture: pathRobot,
        description: 'Notre truc c’est la <b>technologie</b> ! Et on touche à tous les domaines pour tâcher d’améliorer votre quotidien.',
    },
    {
        picture: pathPipette,
        description: 'La taille de notre équipe nous permet de nous <b>adapter</b> facilement à vos demandes.',
    },
    {
        picture: pathMessages,
        description: 'Nous serons toujours disponibles pour vous <b>écouter</b> et vous <b>proposer des solutions</b>.',
    },
]

const AboutUs = () => (
    <section className="container-about-us">
        <div className="container">
            <h1>Qui sommes nous ?</h1>
            <p>
                AUSSIITO est une entreprise du groupe Pyramide et travaille en étroite collaboration avec E-Motors depuis 1998.
                <br />
                Fort de cette expérience nous avons décidé de mettre nos compétences et talents à l’œuvre à travers l’outil Tetraweb !
            </p>
            <h2>Aussiito en quelques dates</h2>
            <div className="slider">
                <button type="button" className="swiper-button-prev">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z"/></svg>
                </button>
                <Swiper
                    slidesPerView={1}
                    spaceBetween={10}
                    navigation={{
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev',
                    }}
                    loop
                    modules={[Navigation]}
                    breakpoints={{
                        640: {
                            slidesPerView: 2,
                            spaceBetween: 20,
                        },
                        768: {
                            slidesPerView: 3,
                            spaceBetween: 20,
                        },
                        1024: {
                            slidesPerView: 5,
                            spaceBetween: 20,
                        },
                    }}
                    className="mySwiper"
                >
                    {history.map((h, i) => (
                        <SwiperSlide key={i}>
                            <div key={i} className="card">
                                <img src={h.picture} alt={h.year}/>
                                <div>
                                    <span>{h.year}</span>
                                    <p>{h.description}</p>
                                </div>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
                <button type="button" className="swiper-button-next">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M7.33 24l-2.83-2.829 9.339-9.175-9.339-9.167 2.83-2.829 12.17 11.996z"/></svg>
                </button>
            </div>
            <p>
                Travaillant exclusivement pour le secteur automobile nous avons une excellente connaissance des évolutions du marché et des problématiques rencontrés par les professionnels.
            </p>
            <h2>Notre équipe</h2>
            <div className="team">
                <img src={pathPictureTeam} alt="Equipe AUSSIITO" />
                <p>
                    Notre équipe est majoritairement composé de développeurs ainsi que d’un graphiste, un commercial et la direction.
                    <br />
                    Ensemble nous avons l’énergie et les compétences pour répondre à tous vos projets web liés à l’automobile !
                    <br />
                    Vous pouvez compter sur notre expertise et notre bonne humeur pour vous accompagner pendant votre transformation digitale 🤖
                </p>
            </div>
            <h2>Nos valeurs</h2>
        </div>
        <div className="bg-primary">
            <div className="container our-values">
                {ourValues.map((v, i) => (
                    <div key={i} className="card">
                        <div>
                            <img src={v.picture} alt={v.picture}/>
                        </div>
                        <p dangerouslySetInnerHTML={{__html: v.description}} />
                    </div>
                ))}
            </div>
        </div>
        <div className="container">
            <h2>Nous recrutons !</h2>
            <p>
                Travailler chez Aussiito c’est aussi rejoindre une équipe expérimentée et sympathique dans des conditions idéales.
                Nous mettons à disposition de nos collaborateurs un environnement de travail idéal, que ce soit à travers le matériel fourni, la souplesse sur les horaires ou le télétravail qui est plus facilement envisageable sur ce type de poste !
            </p>
        </div>
    </section>
);


export default AboutUs;